import { FormConfig } from 'types';

const DefaultTheme = {
  errorColor: '#F02B00',
  primary: '#0048FF',
  white: '#FFFFFF',
  black: '#000000',
  labelTextColor: '#18191A',
  widgetBackgroundColor: '#0048FF',
};

const Position = {
  desktop: {
    left: '20px',
    right: '20px',
    bottom: '100px',
    buttonBottom: '20px',
  },
  mobile: {
    left: '0px',
    right: '0px',
    bottom: '100px',
  },
};

const positionLeft = (element: any) => {
  element.style.setProperty('--verseCaptureButtonLeftPosition', Position.desktop.left);
  element.style.setProperty('--verseCaptureButtonPromptLeftPosition', Position.desktop.left);
  element.style.setProperty('--verseCaptureFormLeftPosition', Position.desktop.left);
  element.style.setProperty('--verseCaptureFormLeftPositionMobile', Position.mobile.left);
  element.style.setProperty('--verseCaptureButtonPromptBorderRadius', '16px 16px 16px 0px');
  // reset right values
  element.style.setProperty('--verseCaptureButtonRightPosition', '');
  element.style.setProperty('--verseCaptureButtonPromptRightPosition', '');
  element.style.setProperty('--verseCaptureFormRightPosition', '');
  element.style.setProperty('--verseCaptureFormRightPositionMobile', '');
};

const positionRight = (element: any) => {
  element.style.setProperty('--verseCaptureButtonRightPosition', Position.desktop.right);
  element.style.setProperty('--verseCaptureButtonPromptRightPosition', Position.desktop.right);
  element.style.setProperty('--verseCaptureFormRightPosition', Position.desktop.right);
  element.style.setProperty('--verseCaptureFormRightPositionMobile', Position.mobile.right);
  element.style.setProperty('--verseCaptureButtonPromptBorderRadius', '16px 16px 0px 16px');
  // reset left values
  element.style.setProperty('--verseCaptureButtonLeftPosition', '');
  element.style.setProperty('--verseCaptureButtonPromptLeftPosition', '');
  element.style.setProperty('--verseCaptureFormLeftPosition', '');
  element.style.setProperty('--verseCaptureFormLeftPositionMobile', '');
};

const positionBottom = (element: any) => {
  element.style.setProperty('--verseCaptureButtonBottomPosition', Position.desktop.buttonBottom);
  element.style.setProperty('--verseCaptureButtonPromptBottomPosition', Position.desktop.bottom);
  element.style.setProperty('--verseCaptureFormBottomPosition', Position.desktop.bottom);
  element.style.setProperty('--verseCaptureFormBottomPositionMobile', Position.mobile.bottom);
};

const setCapturePosition = (config: FormConfig, element: any) => {
  switch (config.position) {
    case 'bottom-left':
      positionBottom(element);
      positionLeft(element);
      break;
    case 'bottom-right':
    default:
      positionBottom(element);
      positionRight(element);
  }
};

export const setTheme = (config: FormConfig, element = document.documentElement) => {
  element.style.setProperty('--verseErrorColor', config.errorColor || DefaultTheme.errorColor);
  element.style.setProperty('--verseTitleTextColor', config.titleTextColor || DefaultTheme.white);
  element.style.setProperty('--verseTitleBackgroundColor', config.titleBackgroundColor || DefaultTheme.primary);
  element.style.setProperty('--verseLabelTextColor', config.labelTextColor || DefaultTheme.labelTextColor);
  element.style.setProperty('--verseFormBackgroundColor', config.formBackgroundColor || DefaultTheme.white);
  element.style.setProperty('--verseLogoColor', config.logoColor || DefaultTheme.white);
  element.style.setProperty('--verseWidgetBackgroundColor', config.widgetBackgroundColor || DefaultTheme.primary);
  element.style.setProperty('--greetingIconColor', config.greetingIconColor || DefaultTheme.primary);
  element.style.setProperty('--greetingTextColor', config.greetingTextColor || DefaultTheme.black);
  element.style.setProperty('--greetingBackgroundColor', config.greetingBackgroundColor || DefaultTheme.white);
  setCapturePosition(config, element);
};
