import { Event } from 'types';

export async function postEvent(event: Event): Promise<void> {
  // fetch API is not supported
  if (!window.fetch) {
    return new Promise((resolve, reject) => reject());
  }

  await fetch(`${process.env.REACT_APP_CORE_URL}/api/widget/web-form-events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      web_form_key_id: event.formKey,
      session_id: event.sessionId,
      url: event.referrer ?? null,
      event_date: new Date().toISOString(),
      event_type: event.eventType,
    }),
  });
}
